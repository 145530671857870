<!-- This Vue component is the section on the New Patient and Patient Detail pages that shows reserve data details. -->
<template>
    <b-card class="top-row-section py-2">
        <IodReportPopup v-if="modalShow" :modalShow.sync="modalShow" :lensOrderId="lensOrderId" />
        <b-row class="mb-4">
            <b-col class="mt-2">
                <h5 class="heavy d-inline mr-1">{{ t('reserve') }}</h5>
                <span class="text-gray-dark">
                    {{ t('viewingAllReserves') }}
                </span>
            </b-col>
        </b-row>
        <b-table
            class="no-outer-padding reserve-card-table"
            :fields="fields"
            :items="reservedLenses"
            sticky-header
            ref="reserveTableContainer"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>
            <template #cell(updated)="data">
                {{ data.value | date }}
            </template>
            <template #cell(ocosSalesOrderId)="{value}">
                <router-link
                    :to="{
                        name: 'ReserveDetails',
                        params: {orderId: value},
                    }"
                    class="record-link text-primary-dark mb-1"
                >
                    {{ value }}
                </router-link>
            </template>
            <template #cell(targetLensDescription)="{item, value}">
                <p class="text-nowrap text-caption heavy text-black mb-1 pl-0 ml-0">
                    <b-img :src="imageSrc(item.opEye)" class="pr-1"></b-img>
                    {{ decimalSeparatorFormatter(value.model, decimalSeparator) }}
                </p>
                <p class="text-caption light text-gray-dark mb-0">
                    {{ lensDescriptionObjToString(value, decimalSeparator) }}
                </p>
            </template>
            <template #cell(reservedLensDescription)="{value}">
                <LensModel :lensDescriptionDetails="value" />
                <div class="d-flex">
                    <LensPrescription :lensDescriptionDetails="value" />
                    <span
                        v-if="value.serial"
                        class="text-caption light text-gray-dark mb-0 white-space-pre"
                    >
                        #{{ value.serial }}</span
                    >
                </div>
            </template>
            <template #cell(status)="{value, item}">
                {{ value }}<br />
                <p
                    class="text-caption light text-gray-dark mb-0"
                    v-if="
                        [
                            OrderSubStatus.RESERVED,
                            OrderSubStatus.IN_CART,
                            OrderSubStatus.CONSUMED,
                        ].includes(value)
                    "
                >
                    <span v-if="isConsignment(item)"> {{ t('invLookup_Consignment') }} </span>
                    <span v-else>
                        {{ t('short_expiration') }} {{ item.expirationDate | date }}</span
                    >
                </p>
                <router-link
                    v-if="value == OrderSubStatus.ORDERED"
                    :to="{
                        name: 'OrderDetails',
                        params: {orderId: item.followUpOrderHeaderId},
                    }"
                    class="record-link text-primary-dark mb-1"
                >
                    {{ item.followUpOrderHeaderId }}
                </router-link>
            </template>
            <template #cell(doctorName)="{value, item}">
                <div class="d-flex align-items-start">
                    <span class="text-caption heavy">
                        {{ surgeonIdNameFormat(item.ocosDoctorId, value, item.doctorActive) }}
                    </span>
                </div>
            </template>
            <template #cell(actions)="data">
                <div class="cell-w-buttons justify-content-end">
                    <b-button variant="outline-primary mr-2" @click="detailsBtnClicked(data.item)">
                        {{ t('details') }}</b-button
                    >
                    <b-button
                        v-if="isConsignment(data.item)"
                        variant="outline-primary"
                        @click="iodBtnClicked(data.item)"
                    >
                        {{ isSidButton(data.item) }}</b-button
                    >
                </div>
            </template>
        </b-table>
    </b-card>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import {
    surgeonIdNameFormat,
    lensDescriptionObjToString,
    decimalSeparatorFormatter,
} from '@/utilities/formatters';
import ODIcon from '@/assets/od_icon.svg';
import OSIcon from '@/assets/os_icon.svg';
import {OrderSubStatus} from '@/constants/order';
import {InventorySource} from '@/constants/inventory';
import IodReportPopup from '@/views/iodreport/IodReportPopup.vue';
import LensPrescription from '@/components/LensPrescription';
import LensModel from '@/components/LensModel';

export default {
    name: 'ReserveCard',
    components: {
        LensModel,
        LensPrescription,
        IodReportPopup,
    },
    data() {
        return {
            ODIcon,
            OSIcon,
            OrderSubStatus,
            lensOrderId: null,
            modalShow: false,
            fields: [
                {key: 'updated', label: this.t('date')},
                {key: 'ocosSalesOrderId', label: this.t('number')},
                {key: 'targetLensDescription', label: this.t('targetLens')},
                {key: 'reservedLensDescription', label: this.t('reservedLens')},
                {key: 'status', label: this.t('statusNormalCase')},
                {key: 'doctorName', label: this.t('surgeon')},
                {key: 'actions', label: this.t('actions')},
            ],
            colWidths: {
                updated: '150px',
                ocosSalesOrderId: '100px',
                targetLensDescription: '200px',
                reservedLensDescription: '200px',
                status: '150px',
                doctorName: '200px',
                actions: '100px',
            },
        };
    },

    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapState({
            reservedLenses: (state) =>
                state.patient.currentPatientLenses.filter((lens) => lens.isReservation),
        }),
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },
    },

    methods: {
        surgeonIdNameFormat,
        lensDescriptionObjToString,
        decimalSeparatorFormatter,
        imageSrc(opEye) {
            return this[`${opEye}Icon`];
        },
        isConsignment(lens) {
            return lens.lensSourceId == InventorySource.CONSIGNMENT;
        },
        detailsBtnClicked({ocosSalesOrderId}) {
            this.$router.push({
                name: 'ReserveDetails',
                params: {orderId: ocosSalesOrderId},
            });
        },

        /**
         * Determines if the lens is a Spheric or Toric for the IOD/SID button
         *
         * @param {Object} lensDetails - the lens details
         * returns button text for Sperhic or Toric
         */
        isSidButton(lensDetail) {
            console.log(lensDetail);
            return lensDetail.reservedLensDescription.cylinder === undefined ? 'SID' : 'IOD';
        },
        /**
         * Handles when the IOD button is clicked
         *
         * @param {Object} lensDetails - the lens details
         */
        iodBtnClicked(lensDetails) {
            this.lensOrderId = lensDetails.lensOrderId.toString();
            this.modalShow = true;
        },
    },
};
</script>
