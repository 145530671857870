<template>
    <b-container id="mainContainer" v-if="iodReport && display">
        <div v-if="debug" id="debugForm">
            <div class="formItem">
                <div>Debugging Utils</div>
            </div>

            <div class="formItem">
                <label for="isToric">Is Toric</label>
                <input id="isToric" type="checkbox" v-model="iodReport.eye.isToric" />
            </div>

            <div class="formItem">
                <label for="firstName">First Name</label>
                <input id="firstName" type="input" v-model="iodReport.firstName" />
            </div>

            <div class="formItem">
                <label for="lastName">Last Name</label>
                <input id="lastName" type="input" v-model="iodReport.lastName" />
            </div>
        </div>

        <b-container id="reportContainer">
            <IodReport
                embedded
                :lensOrderId="lensOrderId"
                :mendezRing="mendezRing"
                :eyeOutline="eyeOutline"
                @error="onCompError"
                :lensOverride_SerialNum="lensOverride_SerialNum"
            />
        </b-container>

        <b-container id="controlsContainer">
            <div id="reportTitle" v-if="iodReport.eye.isToric">
                {{ t('iodreport_Title') }}
            </div>
            <div v-else id="reportTitle">{{ t('iodreport_SphericImpDiagram') }}</div>

            <div id="sliderContainer">
                <b-form-checkbox
                    switch
                    class="custom-checkbox-label no_selection"
                    v-model="mendezRing"
                    :disabled="compError"
                >
                    {{ t('iodreport_ShowMendez') }}
                </b-form-checkbox>

                <b-form-checkbox
                    switch
                    class="custom-checkbox-label no_selection"
                    v-model="eyeOutline"
                    :disabled="compError"
                >
                    {{ t('iodreport_ShowEyeOutline') }}
                </b-form-checkbox>
            </div>

            <div id="buttonContainer">
                <b-button
                    id="fullscreen-button"
                    block
                    @click="printClick(true)"
                    :disabled="compError"
                >
                    {{ t('iodreportpopup_Fullscreen') }}
                    <b-icon class="pointer ml-2" icon="fullscreen"></b-icon>
                </b-button>
                <b-button id="print-button" block @click="printClick(false)" :disabled="compError">
                    {{ t('print') }}
                    <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                </b-button>
                <b-button id="cancel-button" block @click="cancelKeyClicked">
                    {{ t('cancel') }}
                </b-button>
            </div>
        </b-container>
    </b-container>
</template>

<script>
import IodReport from '@//views/iodreport/IodReport.vue';
import {mapGetters} from 'vuex';
import {definedAndNotBlankObj} from '@/utilities/object';

export default {
    components: {
        IodReport,
    },
    data() {
        return {
            compError: false,
            mendezRing: true,
            eyeOutline: true,
            iodReport: null,
            iodReportDebug: {
                firstName: 'Bartholomewzz',
                lastName: 'Featherstonethrowzz',
                eye: {
                    isToric: true,
                },
            },
        };
    },
    props: {
        debug: {
            type: Boolean,
            default: false,
        },

        display: {
            type: Boolean,
            default: false,
        },

        lensOrderId: {
            type: String,
            required: true,
        },

        cancelClicked: {
            default: false,
        },

        lensOverride_SerialNum: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        definedAndNotBlankObj,
        printClick(dLPrintDialog = false) {
            let query = {
                eyeOutline: this.eyeOutline,
                mendezRing: this.mendezRing,
            };

            if (this.lensOverride_SerialNum)
                query.lensOverride_SerialNum = this.lensOverride_SerialNum;

            if (this.debug) query.debug = true;
            if (dLPrintDialog) query.dontLaunchPrintDialog = true;

            const routeData = this.$router.resolve({
                name: 'IodReport',
                params: {
                    lensOrderId: this.lensOrderId,
                },
                query: query,
            });

            window.open(routeData.href, '_blank');
        },
        cancelKeyClicked() {
            this.$emit('update:cancelClicked', true);
            this.showIodReportUI = false;
        },
        onCompError(value) {
            this.compError = value;
        },
        async loadReport() {
            let fetchIodReportObj = {};
            if (this.lensOrderId != undefined) {
                //We are running embedded so use this value
                fetchIodReportObj.lensOrderId = this.lensOrderId;
            }

            if (
                fetchIodReportObj.lensOrderId == undefined ||
                fetchIodReportObj.lensOrderId.length == 0
            ) {
                //We have a problem so alert
                //Todo - Does this go to toast?
                alert('Error: The lensOrderId is empty');
            } else if (fetchIodReportObj.lensOrderId == 'zzz') {
                this.iodReport = this.iodReportDebug;
            } else if (this.lensOverride_SerialNum) {
                fetchIodReportObj.serialNum = this.lensOverride_SerialNum;
                await this.blockingRequest(
                    'iodreport/fetchIodReportWithOverride',
                    fetchIodReportObj
                );
                this.iodReport = this.iodReportRemote;
            } else {
                await this.blockingRequest('iodreport/fetchIodReport', fetchIodReportObj);
                this.iodReport = this.iodReportRemote;
            }
        },
    },
    computed: {
        ...mapGetters({
            iodReportRemote: 'iodreport/iodReport',
        }),
    },
    watch: {
        display: function (newVal, oldVal) {
            if (newVal) this.loadReport();
        },
    },
};
</script>
<style>
#controlsContainer {
    position: absolute;
    left: 628px;
    background-color: white;
    height: 100%;
    font-size: 2.14rem;
    width: 357px;
    padding-top: 25px;
}

#mainContainer {
    /*background-color: red;*/
    background-color: white;
    width: 1136px;
    max-width: 100%;
    height: 820px;
    max-height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    -moz-column-gap: 0px;
    column-gap: 0px;
    overflow: hidden;
    border-radius: 16px;
}

#reportContainer {
    background-color: green;
    max-width: 1500px;
    transform: scale(0.54);
    position: absolute;
    top: 4px;
    left: -249px;
}

#reportTitle {
    color: #10a8ba;
    font-weight: bold;
    font-size: 1.14rem;
    text-transform: uppercase;
}

#patientName {
    font-size: 1.4rem;
    padding-top: 13px;
    font-weight: 600;
}

#sliderContainer {
    /*background-color: lightblue;*/
    margin-top: 40px;
    color: #91979d;
}

#buttonContainer {
    /*background-color: orange;*/
    height: 147px;
    bottom: 5px;
    position: absolute;
    width: 100%;
    left: 0px;
}

#buttonContainer > button {
    padding: 11px 0px;
    font-size: 0.857rem;
}

#print-button,
#fullscreen-button {
    background-color: #0c7e8b;
    color: white;
    border: white;
}

#fullscreen-button {
    background-color: #10a8ba;
}

#cancel-button {
    color: #91979d;
    border: 1px solid #91979d;
    border-radius: 3px;
}

#debugForm {
    position: absolute;
    z-index: 10;
    top: 0px;
    background-color: aliceblue;
    left: 950px;
    width: 351px;
}

/*Todo - Need to fix the styles to remove this selector*/
#buttonContainer button:disabled {
    opacity: 0.6;
}
</style>
