<template>
    <b-modal
        id="modalContainer"
        :visible="modalShow"
        size="xl"
        :content-class="{
            iodReportUI: showIodReportUI,
            modalContainerContent: true,
        }"
        hide-header
        hide-footer
        @hide="hideModal"
    >
        <div v-if="!showIodReportUI && iodReport">
            <div id="reportTitle2" v-if="iodReport.eye.isToric">
                {{ t('iodreportpopup_Title') }}
            </div>
            <div v-else id="reportTitle2">{{ t('iodreportpopup_SphericImpDiagram') }}</div>

            <div id="iodreportpopup-header">
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="10">
                                <div id="nameContainer">
                                    <p class="text-big mb-1 word-wrap ml-0">
                                        {{ definedAndNotBlankObj(iodReport, 'patientOcosId', '—') }}
                                    </p>
                                    <p
                                        v-if="!activeCustomer.hidePatientNames"
                                        class="text-display light text-white-light mb-1 word-wrap ml-2"
                                    >
                                        <span id="iod-report-first-name">
                                            {{
                                                namesFormatter(
                                                    iodReport.lastName,
                                                    iodReport.firstName
                                                )
                                            }}
                                        </span>
                                    </p>
                                    <p
                                        id="genderContainer"
                                        class="text-display light text-white-light mb-1 word-wrap ml-2"
                                    >
                                        <span id="iod-report-gender">
                                            {{ genderHandling(iodReport) }}
                                        </span>
                                    </p>
                                </div>
                            </b-col>
                            <b-col cols="2">
                                <p class="pt-2 mb-0 text-white-light">
                                    {{ iodReport.dob | date({isUTC: false}) }}
                                </p>
                                <p class="pt-0 text-display light">
                                    {{ definedAndNotBlankObj(iodReport, 'yearsOld', '—') }}
                                    {{ t('yrs') }}
                                </p>
                            </b-col>
                        </b-row>
                        <hr class="mt-2 mb-4 horizontal-line" />
                        <b-row id="row2">
                            <b-col cols="6" class="mb-4">
                                <div class="d-inline-flex">
                                    <div>
                                        <p
                                            class="text-caption heavy text-white-lighter surgeon-line mb-0"
                                        >
                                            {{ t('surgeon').toUpperCase() }}
                                        </p>
                                        <p class="text-display light mb-0">
                                            {{ definedAndNotBlankObj(iodReport, 'surgeon', '—') }}
                                        </p>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <p class="text-caption heavy text-white-lighter mb-0">
                                    {{ t('targetLens').toUpperCase() }}
                                </p>
                                <p id="targetLens" class="pt-0 text-display light">
                                    <span>
                                        {{ targetLensDescription }}
                                    </span>
                                    <span id="targetLensPrescription">
                                        {{ targetLensPrescription }}
                                    </span>
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="2" id="eyeColumn">
                        <div id="rightEye" class="eyeContainer" v-if="iodReport.eye.name == 'OD'">
                            <img
                                id="rightEyeImg"
                                class="eyeImg"
                                src="@/assets/reports/OD-Icon.png"
                            />
                        </div>
                        <div id="leftEye" class="eyeContainer" v-if="iodReport.eye.name == 'OS'">
                            <img
                                id="leftEyeImg"
                                class="eyeImg"
                                src="@/assets/reports/OS-Icon.png"
                            />
                        </div>
                    </b-col>
                </b-row>
            </div>

            <div id="infoText" v-if="iodReport.eye.isToric">
                {{ t('iodreportpopup_Details') }}
            </div>
            <div id="infoText" v-else>
                {{ t('iodreportpopup_SidDetails') }}
            </div>

            <div id="lensOrderedText">{{ t('lensOrdered') }}</div>

            <div class="sectionTable">
                <table id="sectionTable">
                    <tr class="headerRow">
                        <th>{{ t('preopreport_SerialNum') }}</th>
                        <th>{{ t('preopreport_Model') }}</th>
                        <th>{{ t('version') }}</th>
                        <th v-if="!lensOverrideMode">{{ t('preopreport_Exp_SEQ') }}</th>
                        <th v-if="!lensOverrideMode">
                            {{ t('iodreportpopup_Exp_Ref') }}
                        </th>
                        <th>{{ t('preopreport_Sphere') }}</th>
                        <th v-if="iodReport.eye.isToric">{{ t('preopreport_Cylinder') }}</th>
                        <th v-if="iodReport.eye.isToric">{{ t('preopreport_Axis') }}</th>
                    </tr>
                    <tr>
                        <td v-if="!lensOverrideMode" class="orderCell">
                            {{ definedAndNotBlankObj(iodReport.eye, 'serialNum', '—') }}
                        </td>
                        <td v-else class="orderCell">
                            <b-form-input
                                id="lensOverride_SerialNum"
                                :state="lensOverride_SerialState"
                                v-model="lensOverride_SerialNum"
                            />
                        </td>

                        <td class="orderCell" v-html="modelFormatted"></td>
                        <td class="orderCell">
                            {{ definedAndNotBlankObj(iodReport.eye, 'version', '—') }}
                        </td>

                        <td v-if="!lensOverrideMode" class="orderCell">
                            {{
                                formatWithPadding(
                                    definedAndNotBlankObj(iodReport.eye, 'expSeq'),
                                    decimalSeparator,
                                    2,
                                    4
                                )
                            }}
                        </td>

                        <td v-if="!lensOverrideMode" class="orderCell">
                            {{ expRefFormatted }}
                        </td>

                        <td
                            v-if="!lensOverrideMode"
                            :class="['orderCell', highlightText('sphere', 'targetLensSphere')]"
                        >
                            {{
                                formatWithPadding(
                                    definedAndNotBlankObj(iodReport.eye, 'sphere'),
                                    decimalSeparator,
                                    2,
                                    4
                                )
                            }}
                        </td>
                        <td v-else class="orderCell">
                            <b-form-input
                                id="lensOverride_Sphere"
                                v-model="lensOverride_Sphere"
                                :state="lensOverride_SphereState"
                                :formatter="formatInput"
                                autocomplete="off"
                                lazy
                            />
                        </td>

                        <td
                            v-if="iodReport.eye.isToric && !lensOverrideMode"
                            :class="['orderCell', highlightText('cylinder', 'targetLensCylinder')]"
                        >
                            {{
                                formatWithPadding(
                                    definedAndNotBlankObj(iodReport.eye, 'cylinder'),
                                    decimalSeparator,
                                    2,
                                    4
                                )
                            }}
                        </td>
                        <td v-if="iodReport.eye.isToric && lensOverrideMode" class="orderCell">
                            <b-form-select
                                id="lensOverride_Cylinder"
                                :state="lensOverride_CylinderState"
                                v-model.number="lensOverride_Cylinder"
                                :options="cylinderOptions"
                            >
                            </b-form-select>
                        </td>

                        <td
                            v-if="iodReport.eye.isToric && !lensOverrideMode"
                            :class="['orderCell', highlightText('axis', 'targetLensAxis')]"
                        >
                            {{
                                formatWithPadding(
                                    definedAndNotBlankObj(iodReport.eye, 'axis'),
                                    decimalSeparator,
                                    0,
                                    3,
                                    false
                                )
                            }}
                        </td>
                        <td v-if="iodReport.eye.isToric && lensOverrideMode" class="orderCell">
                            <b-form-input
                                id="lensOverride_Axis"
                                :state="lensOverride_AxisState"
                                v-model="lensOverride_Axis"
                                :formatter="(val, $event) => formatInput(val, $event, [], 0, 3)"
                                autocomplete="off"
                                lazy
                            />
                        </td>
                    </tr>
                </table>
            </div>

            <div id="btnBar">
                <b-button
                    id="overrideBtn"
                    class="mr-1"
                    variant="primary"
                    v-if="!lensOverrideMode && iodReport.eye.isToric && hasIodOverridePermission"
                    @click="lensOverrideMode = true"
                    >{{ t('iodreportpopup_Override') }}</b-button
                >
                <b-button
                    id="cancelBtn"
                    class="mr-1"
                    variant="outline-secondary"
                    @click="hideModal"
                    >{{ t('cancel') }}</b-button
                >
                <b-button v-if="iodReport.eye.isToric" variant="primary" @click="genIodClick">
                    {{ t('iodreportpopup_GenIod') }}
                </b-button>
                <b-button v-else variant="primary" @click="genIodClick">
                    {{ t('iodreportpopup_GenSid') }}
                </b-button>
            </div>
        </div>

        <IodReportUI
            :lensOrderId="lensOrderId"
            :cancelClicked.sync="cancelClicked"
            :debug.sync="modalDebug"
            :lensOverride_SerialNum="lensOverride_SerialNum"
            :display="showIodReportUI"
        />
    </b-modal>
</template>
<script>
import IodReportUI from '@/views/iodreport/IodReportUI.vue';
import get from 'lodash/get';
import {mapGetters} from 'vuex';
import {
    formatInput,
    formatWithPadding,
    genderHandling,
    namesFormatter,
    decimalSeparatorFormatter,
    parseToNumber,
} from '@/utilities/formatters';
import {definedAndNotBlankObj} from '@/utilities/object';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const CYLINDER_OPTIONS_CONFIG = {
    JUMP: 0.5,
    MIN: 0.5,
    MAX: 6.0,
};

export default {
    name: 'IodReportPopup',

    components: {
        IodReportUI,
    },

    data() {
        return {
            modalDebug: false,
            showIodReportUI: false,
            iodReport: null,

            lensOverride_SerialNum: null,
            lensOverride_Sphere: null,
            lensOverride_Cylinder: null,
            lensOverride_Axis: null,

            lensOverride_SerialState: null,
            lensOverride_SphereState: null,
            lensOverride_CylinderState: null,
            lensOverride_AxisState: null,

            iodReportDebug: {
                firstName: 'Bartholomewz',
                lastName: 'Fea',
                gender: 'M',
                dob: '11/21/1980z',
                yearsOld: 39,
                surgeon: 'Dr. John Medinaz',
                eye: {
                    name: 'OD',
                    isToric: true,
                    targetLens: 'Toric Myopic 13.7',
                    targetLensPrescription: '-07.50 / +2.0 X090',
                    serialNum: 'T12345xxz',
                    model: 'VTICM5_12.1',
                    version: 'EVO+ Visian ICL',
                    sphere: -12,
                    cylinder: 2,
                    axis: -5,
                    expRef: '-00.25 / +00.06 / 090',
                    expSeq: 0.1,
                },
            },
            lensOverrideMode: false,
        };
    },

    computed: {
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('user', ['activeCustomer', 'currentUser']),
        ...mapGetters({
            iodReportRemote: 'iodreport/iodReport',
        }),
        hasIodOverridePermission() {
            return this.checkPermissions({
                [PERMISSIONS.IOD_OVERRIDE]: [PERMISSIONS_VALUES.ENABLED],
            });
        },
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },
        cylinderOptions() {
            const options = [];
            let value = CYLINDER_OPTIONS_CONFIG.MIN;
            while (value <= CYLINDER_OPTIONS_CONFIG.MAX) {
                options.push({
                    value,
                    text: formatWithPadding(value, this.decimalSeparator, 1, 0),
                });
                value += CYLINDER_OPTIONS_CONFIG.JUMP;
            }
            return options;
        },
        targetLensDescription() {
            return decimalSeparatorFormatter(
                definedAndNotBlankObj(this.iodReport.eye, 'targetLens', ''),
                this.decimalSeparator
            );
        },
        targetLensPrescription() {
            return decimalSeparatorFormatter(
                definedAndNotBlankObj(this.iodReport.eye, 'targetLensPrescription', ''),
                this.decimalSeparator
            );
        },
        expRefFormatted() {
            return decimalSeparatorFormatter(
                definedAndNotBlankObj(this.iodReport.eye, 'expRef', ''),
                this.decimalSeparator
            );
        },
        modelFormatted() {
            const model = definedAndNotBlankObj(this.iodReport.eye, 'model', '—');
            if (!this.iodReport.eye.lengthDiff) return model;
            const matches = model.match(/\d+(\.\d+)?/g);
            const length = matches[1] ?? matches[0];
            return length
                ? model.replace(new RegExp(length, 'g'), `<span class="text-red">${length}</span>`)
                : model;
        },
        cancelClicked: {
            set: function (value) {
                if (value) this.hideModal();
            },

            get: function () {
                return false;
            },
        },
    },

    props: {
        modalShow: {
            default: false,
        },

        lensOrderId: {
            type: String,
        },
    },

    methods: {
        formatWithPadding,
        genderHandling,
        definedAndNotBlankObj,
        namesFormatter,
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        /** Formatter for numeric input fields */
        formatInput(value, event, allowedSymbols = ['-', '+'], decimalPlaces = 2, maxLength = 6) {
            return formatInput(
                value,
                event,
                allowedSymbols,
                decimalPlaces,
                maxLength,
                this.decimalSeparator
            );
        },
        hideModal() {
            this.$emit('update:modalShow', false);
            this.showIodReportUI = false;
        },
        highlightText(x, y) {
            return {
                'text-red': get(this.iodReport, ['eye', x]) != get(this.iodReport, ['eye', y]),
            };
        },
        async genIodClick() {
            if (this.lensOverrideMode) {
                if (!this.lensOverride_SerialNum) {
                    this.lensOverride_SerialState = false;
                } else {
                    await this.blockingRequest('iodreport/fetchIodReportWithOverride', {
                        lensOrderId: this.lensOrderId,
                        serialNum: this.lensOverride_SerialNum,
                    });

                    //Validate that the values for sph, cyl, axis are correct
                    if (this.iodReportRemote.overrideSerialError != '') {
                        this.lensOverride_SerialState = false;
                    } else this.lensOverride_SerialState = true;

                    //False: then highlight the invalid numbers
                    const sphere = Number(parseToNumber(String(this.lensOverride_Sphere)));
                    if (this.iodReportRemote.eye.sphere != sphere) {
                        this.lensOverride_SphereState = false;
                    } else this.lensOverride_SphereState = true;

                    if (this.iodReportRemote.eye.cylinder != this.lensOverride_Cylinder) {
                        this.lensOverride_CylinderState = false;
                    } else this.lensOverride_CylinderState = true;

                    const axis = Number(parseToNumber(String(this.lensOverride_Axis)));
                    if (this.iodReportRemote.eye.axis != axis) {
                        this.lensOverride_AxisState = false;
                    } else this.lensOverride_AxisState = true;

                    if (
                        this.lensOverride_SerialState &&
                        this.lensOverride_SphereState &&
                        this.lensOverride_CylinderState &&
                        this.lensOverride_AxisState
                    )
                        this.showIodReportUI = true;
                }
            } else {
                this.showIodReportUI = true;
            }
        },
    },

    async mounted() {
        //console.log("route.query.debug" + this.$route.query.debug);
        if (this.$route.query.debug) this.modalDebug = true;

        let fetchIodReportObj = {};
        if (this.lensOrderId != undefined) {
            //We are running embedded so use this value
            fetchIodReportObj.lensOrderId = this.lensOrderId;
        }

        if (
            fetchIodReportObj.lensOrderId == undefined ||
            fetchIodReportObj.lensOrderId.length == 0
        ) {
            //We have a problem so alert
            //Todo - Does this go to toast?
            alert('Error: The lensOrderId is empty');
        } else if (fetchIodReportObj.lensOrderId == 'zzz') {
            this.iodReport = this.iodReportDebug;
        } else {
            //console.log(`lensOrderId: ${fetchIodReportObj.lensOrderId}`);
            await this.blockingRequest('iodreport/fetchIodReport', fetchIodReportObj);
            this.iodReport = this.iodReportRemote;
        }
    },
};
</script>
<style>
.iodReportUI {
    height: 830px;
}
</style>

<style scoped>
.modalContainerContent {
    width: 1207px;
}

#reportTitle2 {
    color: #87d3dd;
    font-weight: 600;
    font-size: 3.93rem;
    height: fit-content;
    padding-bottom: 30px;
    width: 100%;
    margin-left: 6px;
}

#iodreportpopup-header {
    margin-left: -1rem;
    margin-top: -1rem;
    margin-right: -1rem;
    background-color: #0c7e8b;
    color: white;
    margin-bottom: 18px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

#iod-report-first-name {
    min-width: 108px;
    word-wrap: break-word;
    white-space: normal;
    font-size: 2.28rem;
}

#iod-report-gender {
    font-size: 1.4rem;
}

#nameContainer {
    display: flex;
    align-items: center;
    padding: 20px 0px;
    flex-wrap: wrap;
}

.eyeImg {
    margin-top: -8px;
    margin-left: -26px;
}

#targetLens {
    font-size: 1.7rem;
    font-weight: 600;
}

#targetLensPrescription {
    white-space: nowrap;
}

#infoText {
    font-size: 1rem;
    margin: 10px 0px;
}

#lensOrderedText {
    font-size: 1rem;
    font-weight: bold;
    color: #10a8ba;
    margin: 20px 0px;
}

#btnBar {
    text-align: right;
    margin-top: 101px;
}

#cancelBtn {
    color: #91979d;
    border-color: #91979d;
}

th {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
    text-align: center;
    font-weight: 600px;
}

table {
    width: 96%;
    padding: 10px;
    border-spacing: 0px;
    border-collapse: collapse;
    margin: 10px 10px 10px 25px;
}

tr.headerRow {
    background-color: #f9f9f9;
}

td {
    text-align: center;
}

th,
td {
    padding: 12px;
}

#row2 {
    /*margin-top: 47px;*/
}

#eyeColumn {
    margin-top: auto;
    margin-bottom: auto;
}

#overrideBtn {
    float: left;
}

#sectionTable {
    table-layout: fixed;
}
</style>
